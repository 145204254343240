const init = function init(wrapper, container) {
  // when the plan-grid swiper initializes, we need to set wrapper styles
  // so the cards line up properly.
  wrapper.style.width = '100%'; // eslint-disable-line
  wrapper.style.justifyContent = 'flex-start'; // eslint-disable-line
  container.style.height = '490px' // eslint-disable-line
};

const options = {
  init: false,
  centeredSlides: true,
  slidesPerView: 'auto',
  spaceBetween: 20,
  loop: false,
  setWrapperSize: true,
  grabCursor: true,

  pagination: {
    el: '.swiper-pagination-plan-grid',
    clickable: true,
  },

  // Responsive breakpoints
  breakpoints: {
    450: {
      spaceBetween: 20,
    },
    768: {
      spaceBetween: 80,
    },
  },
};

export default {
  init,
  options,
};
