// NOTE: Preference is to use the ES6 module approach to constructing your project JS
import GlobalNav from './lib/GlobalNav';
import HomePage from './lib/HomePage';
import PlansPage from './lib/PlansPage';
import util from './lib/util';

(function main() {
  const isHomePage = !!document.querySelector('.js-swiper-container-feature');
  const isPlansPage = !!document.querySelector('.js-plan-grid');
  const isVoicePage = window.location.pathname.split('/').indexOf('voice') !== -1;
  const globalNav = new GlobalNav();
  const FEATURE_SWIPER_BREAKPOINT = 768;
  const SWIPER_BREAKPOINT = 768;
  let homePage;
  let plansPage;

  globalNav.init();

  if (isHomePage) {
    homePage = new HomePage(FEATURE_SWIPER_BREAKPOINT);
    homePage.init();
  } else if (isPlansPage) {
    util.setFormTrackingID();
    plansPage = new PlansPage(SWIPER_BREAKPOINT);
    plansPage.init();
    if (window.sessionStorage.hasOwnProperty('availablePlans')) { /* eslint-disable-line */
      const { serviceabilityBeam } = JSON.parse(window.sessionStorage.availablePlans);
      if (serviceabilityBeam === 'managed') {
        document.getElementById('js-equipment--standard').style.display = 'none';
      }
    }
  } else if (isVoicePage) {
    util.addVoiceAnnotationToFooter();
  }

  cohesion('preamp:done', () => {
    const hasTrackingEvents = !!document.getElementsByClassName('js-track-cta').length;

    if (hasTrackingEvents) {
      util.addTagularTrackingEvents();
    }
  });
}());
