import util from "./util";

export default class GlobalNav {
  constructor() {
    this.path = window.location.href.split("/");

    this.dropdown = document.querySelector(".js-dropdown");
    this.footerDropdowns = document.getElementsByClassName(
      "nav-footer__dropdown"
    );
    this.hamburger = document.querySelector(".js-hamburger");
    this.links = document.getElementsByClassName("nav__links--link");

    this.toggleDropdown = this.toggleDropdown.bind(this);
  }

  /**
   * Toggles activeClass of the main nav links depending on the current page.
   * If the nav link corresponds to the current page, it will add activeClass,
   * otherwise it will remove it.
   */
  toggleActiveNavLinkClass() {
    const linkArray = util.arrayFrom(this.links);

    linkArray.forEach((link) => {
      const linkString = String(link.innerHTML).toLowerCase();

      if (this.path.indexOf(linkString) !== -1) {
        util.addActiveClass(link);
      } else {
        util.removeActiveClass(link);
      }
    });
  }

  toggleDropdown(event) {
    const { classList } = event.currentTarget;

    if (classList.contains(util.activeClass)) {
      util.removeActiveClass(event.currentTarget);
      util.removeActiveClass(this.dropdown);
    } else {
      util.addActiveClass(event.currentTarget);
      util.addActiveClass(this.dropdown);
    }
  }

  init() {
    this.toggleActiveNavLinkClass();
  }
}
