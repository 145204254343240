function cardTemplate(card, packages) {
  const assetUrl = '/assets/default/images/pkgs';
  const currentCard = packages[card];
  const gb = currentCard.webProductName.split(' ')[0];
  const name = currentCard.webProductName;
  const hasPromo = !!currentCard.promoPrice || false;
  const price = hasPromo
    ? currentCard.promoPrice
    : currentCard.price;
  const saveAmount = Math.round(currentCard.price - currentCard.promoPrice);
  const promoPrice = currentCard.promoPrice
    ? `<span class="plan-card__content--pricing--normal">$${currentCard.price}</span>`
    : '';
  const { promoMonths } = currentCard;
  // this is the logic to determine whether or not the plan qualifies for a promo
  // const promoBubble = (serviceabilityBeam === 'standard' || (serviceabilityBeam === 'growth' && card !== '10GB'))
  //   ? `<div class="plan-card__content--promo">
  //       <p class="plan-card__content--promo__content">Get $50 instant savings</p>
  //     </div>`
  //   : '';
  let hrsStreaming;
  switch (gb) {
  case '10':
    hrsStreaming = 15;
    break;
  case '20':
    hrsStreaming = 30;
    break;
  case '30':
    hrsStreaming = 45;
    break;
  case '50':
    hrsStreaming = 72;
    break;
  default:
    hrsStreaming = '';
  }

  return `
    <div class="swiper-slide swiper-slide-plan-grid">
      <div class="plan-card">

        <div class="plan-card__content" id="${name}">
          <div class="plan-card__content--header">
            <h3 class="plan-card__content--header__title">${name} PLAN</h3>
            <img src="${assetUrl}/${gb}gb.png" alt="" class="plan-card__content--header__image">
          </div>

          <p class="plan-card__content--stat">25 Mbps download speeds<sup data-legal-annotation="generic-speed"></sup></p>
          <p class="plan-card__content--stat">${hrsStreaming} hrs streaming per month<sup data-legal-annotation="streaming"></sup></p>

          <div class="plan-card__content--pricing">
            <span class="plan-card__content--pricing--promo">$${price}
              <span class="plan-card__content--pricing--term">/mo.</span>
              <sup data-legal-annotation-plan-grid="2017-19-95-offer-long"></sup>
            </span>
            ${promoPrice}
          </div>
          ${hasPromo
            ? `<div class="plan-card__content--legal">Save $${saveAmount}/mo. for ${promoMonths} mos.<sup data-legal-annotation="2018-monthly-promo-growth"></sup> <br />Offer ends ${window.stratus.endDate}</div>`
            : ''
          }

            <a
              data-position=""
              data-location="CARD"
              data-elementType="BUTTON"
              data-actionOutcome="SUBMIT"
              data-text="${`Order Now ${name}`}"
              class="btn btn__primary js-track-cta plan-card__content--cta plan-card__content--fuse-number-small"
              data-fuse data-fuse-format='Call Now'
              href='tel:<%- site.data.global.sitePhone %>'>
              Call Now
            </a>

            <a
              data-position=""
              data-location="CARD"
              data-elementType="BUTTON"
              data-actionOutcome="SUBMIT"
              data-text="${`Order Now ${name}`}"
              class="btn btn__primary js-track-cta plan-card__content--cta plan-card__content--fuse-number-medium"
              data-fuse data-fuse-format='1-###-###-####'
              href='tel:<%- site.data.global.sitePhone %>'>
              1-###-###-####
            </a>

        </div>

      </div>
    </div>
  `;
}

export default cardTemplate;
