export default {
  init: true,
  loop: false,
  grabCursor: true,

  pagination: {
    el: '.swiper-pagination-data',
    clickable: true,
  },

  navigation: {
    nextEl: '.swiper-button-next-data',
    prevEl: '.swiper-button-prev-data',
  },
};
