import swiperDataOptions from './swiperDataOptions';
import swiperFeatureOptions from './swiperFeatureOptions';
import util from './util';

export default class HomePage {
  constructor(featureSwiperBreakpoint) {
    this.FEATURE_SWIPER_BREAKPOINT = featureSwiperBreakpoint;

    this.swiperContainer = document.querySelector('.js-swiper-container-feature');
    this.swiperWrapper = document.querySelector('.js-swiper-wrapper-feature');
    this.tabElement = document.querySelector('.js-data-tabs');
    this.tabArray = util.arrayFrom(this.tabElement.children);
  }

  swiperDataInit() {
    // We need to add click handlers to the tabs to tell the swiper which slide
    // to navigate to on click.
    function add(tabs, dataSwiper) {
      tabs.forEach((tab, i) => {
        tab.addEventListener('click', () => {
          if (dataSwiper.activeIndex !== parseInt(i, 10)) {
            dataSwiper.slideTo(i);
          }
        });
      });
    }

    function slideChangeCallback(tabs) {
      tabs.forEach((tab, i) => {
        if (this.activeIndex === parseInt(i, 10)) {
          util.addActiveClass(tab);
        } else {
          util.removeActiveClass(tab);
        }
      });
    }

    const dataSwiper = new Swiper('.js-swiper-container-data', swiperDataOptions); // eslint-disable-line
    // This adds the active class to the currently selected tab
    slideChangeCallback.call(dataSwiper, this.tabArray);
    // This adds the callback to dataSwiper to add the active class to the
    // currently selected tab on every slide change
    dataSwiper.on('slideChange', slideChangeCallback.bind(dataSwiper, this.tabArray));

    // Initialize click handlers
    add(this.tabArray, dataSwiper);
  }

  swiperFeatureInit() {
    let swiper;

    function createSwiper(wrapper, container) {
      // We need to disable this line or the build will fail because Swiper is
      // defined in the vendor.js file. Gulp will think it's 'undefined'
      const swiper = new Swiper('.js-swiper-container-feature', swiperFeatureOptions.options); // eslint-disable-line
      swiper.on('init', swiperFeatureOptions.init(wrapper, container));
      swiper.init();

      return swiper;
    }

    // Initialize or destroy swiper instance when screen size reaches breakpoint
    window.addEventListener('resize', () => {
      const screenWidth = window.innerWidth;

      if (
        (screenWidth <= this.FEATURE_SWIPER_BREAKPOINT && !swiper)
        || (screenWidth <= this.FEATURE_SWIPER_BREAKPOINT && swiper.destroyed)
      ) {
        swiper = createSwiper(this.swiperWrapper, this.swiperContainer);
      } else if (screenWidth > this.FEATURE_SWIPER_BREAKPOINT && !!swiper && !swiper.destroyed) {
        swiper.destroy();
      }
    });

    // we only run this if the swiper wrapper exists
    if (!this.swiperWrapper) {
      return;
    }
    // On first run we need to init the swiper instance if the screen is narrow enough
    if (window.innerWidth <= this.FEATURE_SWIPER_BREAKPOINT) {
      swiper = createSwiper(this.swiperWrapper, this.swiperContainer);
    }
  }

  init() {
    this.swiperFeatureInit();
    this.swiperDataInit();
  }
}
