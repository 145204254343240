const init = function init(wrapper, container) {
  // when the plans swiper initializes, we need to set the wrapper width
  // to 100% and add a margin to the bottom of the container
  wrapper.style.width = '100%'; // eslint-disable-line
  wrapper.style.justifyContent = 'flex-start'; // eslint-disable-line
  container.style.marginBottom = '60px'; // eslint-disable-line
};

const options = {
  init: false,
  centeredSlides: true,
  slidesPerView: 'auto',
  spaceBetween: 20,
  loop: false,
  setWrapperSize: true,
  grabCursor: true,

  pagination: {
    el: '.swiper-pagination-plans',
    clickable: true,
  },

  // Responsive breakpoints
  breakpoints: {
    450: {
      spaceBetween: 18,
    },
    768: {
      spaceBetween: 60,
    },
    1024: {
      spaceBetween: 98,
    },
  },
};

export default {
  init,
  options,
};
