import cardTemplate from './templates/cardTemplate';
// import promoBannerTemplate from './templates/promoBannerTemplate';
import swiperPlansPageOptions from './swiperPlansPageOptions';
import util from './util';

export default class PlansPage {
  constructor(swiperBreakpoint) {
    this.API_REQUEST_OPTIONS = { headers: { 'content-type': 'application/json' } };
    this.API_URL = `${window.hn.getApiHost()}hughesnet/hn-api.php`;
    this.ASSET_URL = '/assets/default/images/pkgs';
    this.availablePlans = window.sessionStorage.availablePlans;
    this.SWIPER_BREAKPOINT = swiperBreakpoint;

    this.address1 = document.querySelector('.js-address1');
    this.addressCheckForm = document.querySelector('.js-addressCheckForm');
    this.addressCheckFormContainer = document.querySelector('.js-address-check');
    this.city = document.querySelector('.js-city');
    this.ctaModal = document.querySelector('.js-cta-modal');
    this.ctaModalImg = document.querySelector('.js-cta-modal-img');
    this.editAddressButtons = document.querySelectorAll('.js-edit-address'); // note plural
    this.hero = document.querySelector('.js-hero');
    this.loadingModal = document.querySelector('.js-loading-modal');
    this.modalClose = document.querySelector('.js-cta-modal-close');
    this.modalPlanName = document.querySelector('.js-cta-modal-plan-name');
    this.featureSections = document.querySelector('.js-feature-sections');
    this.planGridContainerElement = document.querySelector('.js-plan-grid');
    this.promoBannerElement = document.querySelector('.js-promo-banner');
    this.promoString = document.querySelector('.js-cta-modal-promo');
    this.state = document.querySelector('.js-state');
    this.swiperContainer = document.querySelector('.js-swiper-container-plan-grid');
    this.swiperWrapper = document.querySelector('.js-swiper-wrapper-plan-grid');
    this.unserviceableMessage = document.querySelector('.js-unserviceable');
    this.unserviceableHeading = document.querySelector('.js-unserviceable-heading');
    this.unserviceableSubhead = document.querySelector('.js-unserviceable-subhead');
    this.nyServMessage = document.querySelector('.js-ny-serviceability');
    this.freeInstallMessage = document.querySelector('.js-free-install');
    this.zipCodeElements = document.querySelectorAll('.js-plan-grid-zip-code'); // note plural
    this.zip = document.querySelector('.js-zip');

    this.addPlanButtonListeners.bind(this);
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.hideCtaModal = this.hideCtaModal.bind(this);
  }

  /**
   * Adds listener to "edit address" buttons
   * @todo Check with CEs to see if they want this click listener gone, now with the new CTA
   */
  addEditButtonListener() {
    function handleEditButtonsClicked() {
      window.sessionStorage.removeItem('availablePlans');
      window.location.reload();
    }

    util.addListeners(this.editAddressButtons, handleEditButtonsClicked);
  }

  addModalCloseListener() {
    util.addListeners(this.modalClose, this.hideCtaModal);
  }

  addPlanButtonListeners() {
    const { serviceabilityBeam } = JSON.parse(this.availablePlans);
    const planButtons = document.getElementsByClassName('plan-card__content--cta'); /* eslint-disable-line */

    // set the plan name, image file and add the active class to the modal
    const handlePlanButtonClicked = (event) => { /* eslint-disable-line */
      const planName = event.target.parentElement.id.split(' ')[0];
      // this is the logic to determine whether or not the plan qualifies for a promo
      if (serviceabilityBeam === 'standard' || (serviceabilityBeam === 'growth' && planName !== '10')) {
        this.showPromoString();
      } else {
        this.hidePromoString();
      }

      this.modalPlanName.innerHTML = `${planName} GB Plan`;
      this.ctaModalImg.src = `${this.ASSET_URL}/${planName}gb-large.png`;
      this.showCtaModal();
    };
  }

  addUnserviceableMessage(error) {
    this.unserviceableHeading.innerText = error.heading;
    this.unserviceableSubhead.innerText = error.subhead;
    this.showUnserviceableMessage();
  }

  /**
   * Handles user address form submission
   * @param {*} event
   */
  handleFormSubmit(event) {
    event.preventDefault();
    const formData = new FormData(this.addressCheckForm);

    if (this.isFormValid()) {
      this.showLoadingModal();
      window.sessionStorage.setItem('zipCode', this.zip.value);

      axios.post(this.API_URL, formData, this.API_REQUEST_OPTIONS) // eslint-disable-line
        .then((response) => {
          if (response.data.message !== 'serviceable') {
            throw response.data.message;
          }
          const dataString = JSON.stringify(response.data);

          window.sessionStorage.setItem('availablePlans', dataString);
          this.availablePlans = dataString;

          this.init();
          util.addAnnotationsToPage();
          util.addAnnotationsToFooter();
          this.hideLoadingModal();
        })
        .catch((e) => {
          this.addUnserviceableMessage(util.processErrorMessage(e));
          this.hideAddressCheckForm();
          this.hideLoadingModal();
        });
    }
  }

  hideAddressCheckForm() {
    util.removeActiveClass(this.addressCheckFormContainer);
  }

  hideCtaModal() {
    util.removeActiveClass(this.ctaModal);
  }

  hideFeatureSections() {
    util.removeActiveClass(this.featureSections);
  }

  hideHero() {
    util.removeActiveClass(this.hero);
  }

  hideLoadingModal() {
    util.removeActiveClass(this.loadingModal);
  }

  hidePromoString() {
    util.removeActiveClass(this.promoString);
  }

  insertPlanCards() {
    const { serviceabilityBeam } = JSON.parse(this.availablePlans);
    const { packages } = JSON.parse(this.availablePlans);
    const { zipCode } = window.sessionStorage;

    // Let's add the zipcode for the current search to the DOM element
    util.addInnerHTML(this.zipCodeElements, zipCode);

    // Add the cards to the DOM
    Object.keys(packages).forEach((card) => {
      const cardToInsert = cardTemplate(card, packages, serviceabilityBeam);

      // Add the card to the DOM
      this.swiperWrapper.insertAdjacentHTML('beforeend', cardToInsert);
    });

    this.showPlanGrid();

    // We want to make sure Fuse renders numbers when everything is ready...
    if (fuse) { fuse('placeNumbers'); }
  }

  showAddressCheckForm() {
    util.addActiveClass(this.addressCheckFormContainer);
  }

  showCtaModal() {
    util.addActiveClass(this.ctaModal);
  }

  showFeatureSections() {
    util.addActiveClass(this.featureSections);
  }

  showHero() {
    util.addActiveClass(this.hero);
  }

  showLoadingModal() {
    util.addActiveClass(this.loadingModal);
  }

  showPlanGrid() {
    util.addActiveClass(this.planGridContainerElement);
  }

  hidePlanGrid() {
    util.removeActiveClass(this.planGridContainerElement);
  }

  showPromoString() {
    util.addActiveClass(this.promoString);
  }

  showUnserviceableMessage() {
    util.addActiveClass(this.unserviceableMessage);
  }

  /**
   * Show message for NY residents
   */
  showNyMessage() {
    const { zipCode } = window.sessionStorage;

    // Lets add the zipcode for the current search to the DOM element
    util.addInnerHTML(this.zipCodeElements, zipCode);
    util.addActiveClass(this.nyServMessage);
  }

  showFreeInstallMessage() {
    util.addActiveClass(this.freeInstallMessage);
  }

  swiperInit() {
    let swiper;

    function createSwiper(wrapper, container) {
      // We need to disable this line or the build will fail because Swiper is
      // defined in the vendor.js file. Gulp will think it's 'undefined'
      const swiper = new Swiper('.js-swiper-container-plan-grid', swiperPlansPageOptions.options); // eslint-disable-line
      swiper.on('init', swiperPlansPageOptions.init(wrapper, container));
      swiper.init();

      return swiper;
    }

    // Initialize or destroy swiper instance when screen size reaches SWIPER_BREAKPOINT
    window.addEventListener('resize', () => {
      const screenWidth = window.innerWidth;

      if (
        (screenWidth <= this.SWIPER_BREAKPOINT && !swiper)
        || (screenWidth <= this.SWIPER_BREAKPOINT && swiper.destroyed)
      ) {
        swiper = createSwiper(this.swiperWrapper, this.swiperContainer);
      } else if (screenWidth > this.SWIPER_BREAKPOINT && !!swiper && !swiper.destroyed) {
        swiper.destroy();
      }
    });

    // we only run this if the swiper wrapper exists
    if (!this.swiperWrapper) {
      return;
    }
    // On first run we need to init the swiper instance if the screen is narrow enough
    if (window.innerWidth <= this.SWIPER_BREAKPOINT) {
      swiper = createSwiper(this.swiperWrapper, this.swiperContainer);
    }
  }

  isFormValid() {
    const regExpValidators = {
      address1: new RegExp('^([0-9]+[a-zA-Z]{0,})(\\s[\\w.]+)+\\s{0,}$'),
      city: new RegExp('^([a-zA-Z-._]+)+(\\s[a-zA-Z-._]+){0,}$'),
      zip: new RegExp('^[0-9]{5}$'),
      state: new RegExp('^([a-zA-Z]){2}$'),
    };
    const addressFieldsToValidate = {
      address1: {
        element: this.address1,
        isValid: false,
      },
      city: {
        element: this.city,
        isValid: false,
      },
      state: {
        element: this.state,
        isValid: false,
      },
      zip: {
        element: this.zip,
        isValid: false,
      },
    };

    /**
     * check validity of each form field (js-address1Error, js-cityError,
     * js-stateError, js-zipError) and add or remove active class as necessary,
     * and set isValid flag
     */
    Object.keys(addressFieldsToValidate).forEach((key) => {
      const { element } = addressFieldsToValidate[key];
      const { value } = addressFieldsToValidate[key].element;
      const errorMessage = document.querySelector(`.js-${key}Error`);

      if (regExpValidators[key].test(value)) {
        util.removeActiveClass(element);
        util.removeActiveClass(errorMessage);
        addressFieldsToValidate[key].isValid = true;
      } else {
        util.addActiveClass(element);
        util.addActiveClass(errorMessage);
        addressFieldsToValidate[key].isValid = false;
      }
    });

    // return validity of form
    return Object.keys(addressFieldsToValidate)
      .every((key) => addressFieldsToValidate[key].isValid === true);
  }

  /**
   * On init
   */
  init() {
    let inNewYork = false;
    // If we have sessionStorage already, we check if address is in NY
    if (util.hasSessionInfo(window)) {
      const plansSessionObj = JSON.parse(window.sessionStorage.getItem('availablePlans'));
      inNewYork = util.isInNewYork(plansSessionObj);
    }

    if (this.availablePlans && inNewYork) {
      this.showHero();
      this.hideAddressCheckForm();
      this.swiperInit();
      this.addModalCloseListener();
      this.showNyMessage();
      this.addEditButtonListener();
    } else if (this.availablePlans) {
      this.hideHero();
      this.hideAddressCheckForm();
      this.insertPlanCards();
      this.showFeatureSections();
      this.showFreeInstallMessage();
      this.swiperInit();
      this.addPlanButtonListeners();
      this.addModalCloseListener();
      this.addEditButtonListener();
    } else {
      this.hideFeatureSections();
      this.showHero();
      this.addressCheckForm.addEventListener('submit', this.handleFormSubmit);
      this.showAddressCheckForm();
    }

    util.addTagularTrackingEvents();
  }
}
